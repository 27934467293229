@font-face {
    font-family: Avenir;
    src: url("/fonts/Avenir-Light.ttf");
}

@import "popular-fonts";

@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "styles";

@import "~vue-directive-tooltip/src/css/index.scss";
