@import "variables";
html,
body {
    height: 100%;
}
button {
    border-radius: 0;
}
button:focus {
    outline: none;
}

.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0;
}

.cancel-button {
    font-size: 1rem;
    height: 60px;
    width: 250px;
    border-radius: 5px;
    color: $grey;
    background-color: #ffffff;
    border: 1px solid $grey;
    &:hover {
        color: $greyHover;
        border: 1px solid $greyHover;
    }
}
.reset-button {
    font-size: 1.2rem;
    height: 60px;
    width: 150px;
    font-weight: bolder;
    color: $darkBlue;
    // color: $greyHover;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    svg {
        margin-bottom: 5px;
    }
    &:hover {
        color: $blue;
        svg {
            fill: $blue;
        }
    }
}

.green-button {
    font-size: 1rem;
    height: 60px;
    width: 250px;
    border-radius: 5px;
    color: #ffffff;
    background-color: $green;
    border: 0;
    &:hover {
        background-color: $greenHover;
    }
}

.green-border-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    height: 50px;
    color: $green;
    border: 1px solid $green;
    background-color: #fff;
    &:hover {
        background-color: $greenHover;
        color: #fff;
    }
}

.red-button {
    font-size: 1rem;
    height: 60px;
    width: 250px;
    border-radius: 5px;
    color: #ffffff;
    background-color: $red;
    border: 0;
    &:hover {
        background-color: $redHover;
    }
}

button:disabled {
    cursor: auto;
    opacity: 0.3;
}

.button-block {
    width: 100% !important;
}
.edit-row {
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    font-weight: 900;
    .title {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        width: 70%;
        label {
            margin-bottom: 20px;
        }
    }
    .font {
        width: 30%;
    }
    .input {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .toggle {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 25%;
        margin-right: 0 !important;
        .vc-chrome,
        .vc-sketch {
            position: absolute;
            z-index: 5;
        }
    }
    .color-picker {
        width: 50px;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid $inputBorder;
    }
    select {
        cursor: pointer;
        border: 1px solid $inputBorder;
        // width: 80%;
        height: 60px;
        border-radius: 5px;
        padding: 10px;
        font-size: 18px;
        color: $darkBlue;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 20px) calc(1em + 7px),
            calc(100% - 15px) calc(1em + 7px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 0px 1.5em;
        background-repeat: no-repeat;
    }
}

.edit-input {
    height: 100px;
    input {
        border: 1px solid $inputBorder;
        padding: 10px;
        font-size: 18px;
        height: 60px;
        border-radius: 5px;
        color: $darkBlue;
    }
}
.edit-input-logo {
    height: 100%;
}
.edit-input-button {
    height: 150px;
}
.range {
    height: 100px;
    .toggle {
        cursor: default;
        margin-top: 40px;
        font-size: 20px;
    }
}

// style for range
input[type="range"] {
    width: 100%;
    margin: 10px 0;
    background-color: transparent;
    -webkit-appearance: none;
}
input[type="range"]:focus {
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    background: rgba(53, 107, 129, 0.78);
    border: 1.3px solid #356b81;
    border-radius: 1.3px;
    width: 100%;
    height: 0px;
    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
    margin-top: -11.3px;
    width: 20px;
    height: 20px;
    background: #356b81;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #41839e;
}
input[type="range"]::-moz-range-track {
    background: rgba(53, 107, 129, 0.78);
    border: 1.3px solid #356b81;
    border-radius: 1.3px;
    width: 100%;
    height: 0px;
    cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #356b81;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
}
input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 10px 0;
    color: transparent;
    width: 100%;
    height: 0px;
    cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
    background: #295364;
    border: 1.3px solid #356b81;
    border-radius: 2.6px;
}
input[type="range"]::-ms-fill-upper {
    background: rgba(53, 107, 129, 0.78);
    border: 1.3px solid #356b81;
    border-radius: 2.6px;
}
input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #356b81;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
}
input[type="range"]:focus::-ms-fill-lower {
    background: rgba(53, 107, 129, 0.78);
}
input[type="range"]:focus::-ms-fill-upper {
    background: #41839e;
}

@supports (-ms-ime-align: auto) {
    input[type="range"] {
        margin: 0;
    }
}

.template-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modal-backdrop-component {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(54 108 129 / 90%);
    display: flex;
    justify-content: center;
    // align-items: center;
    z-index: 99;
}

.slide-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    .slide-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 700;
        color: #356b81;
        border-bottom: 3px solid transparent;
    }
    .active {
        border-bottom: 3px solid #356b81;
    }
}

.vue-tooltip {
    z-index: 999;
}
.vue-tooltip.tooltip-custom {
    background-color: $darkBlue;
    .tooltip-arrow {
        border-color: $darkBlue !important;
    }
}

.close-icon {
    text-align: right;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.8;
}

// Adjusts for display template

.template-desktop {
    display: none;
}
.template-mobile {
    display: none;
}
.device-mobile,
.device-tablet {
    .template-mobile {
        display: block !important;
    }
    .template-desktop {
        display: none;
    }
    .lc-text-header .lc-text-header-h1,
    .lc-text-header h1 {
        font-size: 25px;
    }
    .lc-first-last {
        display: block;
    }
    .lc-first-last-width {
        width: 100%;
    }
    .lc-checkboxes {
        p {
            font-size: 18px;
        }
    }
}
.device-tablet {
    .lc-first-last {
        display: flex;
    }
    .lc-first-last-width {
        width: 47% !important;
    }
}
.device-desktop {
    .template-mobile {
        display: none;
    }
}

.lc-logo img {
    max-width: 100% !important;
}

// Dropdown
.vs__dropdown-option--highlight {
    background-color: $inputBorder;
}

.vs__dropdown-option {
    display: flex;
}
